import React, { useState, useEffect } from "react";

import jwtDecode from "jwt-decode";
import MemberContext from "../Context";

const MemberProvider = ({ children }) => {
  const getMember = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const member = jwtDecode(token);

      setMember((state) => {
        return {
          ...state,
          member: member,
        };
      });

      return member;
    } else {
      return null;
    }
  };

  const initialState = {
    member: {},
    getMember,
  };

  const [member, setMember] = useState(initialState);

  return (
    <MemberContext.Provider value={member}>{children}</MemberContext.Provider>
  );
};

export default MemberProvider;
