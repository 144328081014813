import React from 'react';

const SignUp1 = React.lazy(() => import('./SCM/Authentication/SignUp/SignUp1'));


const route = [
    { path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },

];

export default route;