import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";

import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import reducer from "./store/reducer";
import config from "./config";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";
import "core-js/es/set";
import "core-js/es/map";
import "./assets/scss/style.scss";

import MemberProvider from "./SCM/Provider";

const middlewares = [thunk];
const store = createStore(reducer, undefined, applyMiddleware(...middlewares));

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <MemberProvider>
        <App />
      </MemberProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
